<template>
    <div>
        <hero :title="post.acf.post_title[currentLang]"/>
        <contents :post="post"/>
    </div>
</template>

<script>
    import i18n from '@//plugins/i18n'
    export default {
        components: {
            Hero: () => import('@/components/posts/Hero'),
            Contents: () => import('@/components/posts/Contents')
        },
        data: () => ({
            post: {}
        }),
        computed:{
            currentLang () {
                return i18n.locale
            }
        },
        mounted(){
            fetch(`https://ktt01.de/wordpress/?rest_route=/post/post&slug=${this.$route.params.slug}&_embed/`)
                .then((r) => r.json())
                .then((res) => this.post = res[0])

            this.$store.dispatch('app/setLink', -1)
        }
    }
</script>
